@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700;&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}
html{
  height:100%;
}
root{
  height:100%;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


  .floating-menu-font {
    font-size:3vw ;
    font-family: 'poppins';
    white-space: nowrap;
    color:white;
  }
  .floating-menu-icons{
  height:3vw 
  }







.mainLoginInput::-webkit-input-placeholder {
    font-family: 'Poppins',sans-serif;
  }
  
  .mainLoginInput:-ms-input-placeholder {
    font-family: 'Poppins',sans-serif;
  }
  
  .mainLoginInput:-moz-placeholder {
    font-family: 'Poppins',sans-serif;
  }
  
  .mainLoginInput::-moz-placeholder {
    font-family: 'Poppins',sans-serif;
  }

.cls-1 {
    fill: url(#linear-gradient);
  }

  .cls-2 {
    opacity: 0.566;
    fill: url(#linear-gradient-2);
  }

  .cls-3 {
    fill: #fff;
    font-size: 28px;
    font-family: Poppins-Bold, Poppins;
    font-weight: 700;
  }

