
.cls-1 {
    fill: url(#linear-gradient);
  }

  .cls-2 {
    opacity: 0.566;
    fill: url(#linear-gradient-2);
  }

  .cls-3 {
    fill: #fff;
    font-size: 28px;
    font-family: Poppins-Bold, Poppins;
    font-weight: 700;
  }
