@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&display=swap');


.mainLoginInput::-webkit-input-placeholder {
    font-family: 'Poppins',sans-serif;
  }
  
  .mainLoginInput:-ms-input-placeholder {
    font-family: 'Poppins',sans-serif;
  }
  
  .mainLoginInput:-moz-placeholder {
    font-family: 'Poppins',sans-serif;
  }
  
  .mainLoginInput::-moz-placeholder {
    font-family: 'Poppins',sans-serif;
  }